import { Atom, Ctrl, state } from ":mods";
import { ShowCoursesViewProps } from "../../models";
import { For, createEffect, createSignal, onCleanup } from "solid-js";
import { getStagesList } from "../../apis";
import { setSelectedStage } from "../../store";
import { ROUTES } from "../../const";
export function ShowCourses({ $ }: ShowCoursesViewProps) {
  const [stagesData, setStagesData] = createSignal([] as any[]);
  const [totalData, setTotalData] = createSignal({
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet, nesciunt obcaecati ducimus animi facere veritatis libero! Nostrum quae atque non debitis ullam sapiente dolor id illo! Molestiae architecto odit doloremque?",
    completed_courses: 0,
    total_courses: 3,
  });
  const $db_stages = state.createAsync(getStagesList);
  $db_stages.on((event, { value }) => {
    console.log("stages :: ", value?.data);
    setStagesData(value?.data);
    const totals = getTotalData(value?.data);
    setTotalData((s) => ({ ...s }));
  });
  $.actions.setLayout({
    title: "Courses",
  });

  $.actions.updateSideNavStatus("hide");

  createEffect(() => {});
  function onStageSelected(id: string | number) {
    setSelectedStage(id);
  }
  onCleanup(() => {
    $.actions.updateSideNavStatus("show");
  });
  function getTotalData(data: any[]) {
    let completed_courses = 0;
    let total_courses = 0;
    for (const course of data) {
      if (course.total_completed_stage === null || course.total_completed_stage === undefined) {
        continue;
      }
      if (course.total_stages === null || course.total_stages === undefined) {
        continue;
      }
      if (course.total_completed_stage >= course.total_stages) {
        completed_courses += 1;
      }
      total_courses += course.stages?.length ?? 0;
    }
    return {
      completed_courses,
      total_courses,
    };
  }
  return (
    <main class="flex flex-col w-99%">
      <div class="flex flex-col gap-20px my-40px">
        <p class="text-16px w-full">{totalData()?.description}</p>
        <hr class="w-842px h-1px bg#nav-side-divider mt-20px opacity-40" />
        <div class="flex justify-between w-full text-16px">
          <p>{totalData()?.total_courses ?? 3} Courses</p>
          <p class="capitalize">{`${totalData()?.completed_courses ?? 0} of ${
            totalData()?.total_courses ?? 0
          } courses completed`}</p>
        </div>
      </div>
      <For each={stagesData()}>
        {(item, index) => {
          const stages = item.stages;
          return (
            <For each={stages}>
              {(item, index) => {
                const { id, name, label, description, ready_to_start, link = "foundation/schedule/" } = item;
                return (
                  <>
                    <div class={`flex gap-22px p-20px my-20px h-198px ${!ready_to_start && "opacity-40"}`}>
                      <div class="bg#p w-197px h-198px" />
                      <div class="text-16px">
                        <p class={ready_to_start && "text#highlight"}>{label}</p>
                        <h1 class="text-25px">{name}</h1>
                        <div class="flex gap-25px mt-20px">
                          <p class="">{description}</p>
                          <Atom.Buttons.ButtonNoAnchor
                            onClick={() => {
                              if (!ready_to_start) {
                                return;
                              }
                              onStageSelected(id);
                              $.actions.navigate(ROUTES.side.courses.foundation(id));
                            }}
                            theme="light"
                            cls={`bg-inherit ${
                              ready_to_start ? " cursor-pointer" : "cursor-not-allowed"
                            } whitespace-nowrap`}
                            outlined
                            text="Start the Course"
                          />
                        </div>
                      </div>
                    </div>
                    <hr class="h-1px bg#nav-side-divider mt-20px w-842px" />
                  </>
                );
              }}
            </For>
          );
        }}
      </For>
    </main>
  );
}
